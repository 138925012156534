import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import NavBar from "../components/navBar"
import Container from "react-bootstrap/Container"

function ThankYouPage({ location }) {
  return (
    <Layout>
      <Seo title="Thank You" />
      <NavBar location={location} />
      <Container style={{ height: "50vh" }}>
        <h1>Demo Requested</h1>
        <p>Thank you for your submission!</p>
      </Container>
    </Layout>
  )
}

export default ThankYouPage
